import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/services/Admin.Service';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialogComponent } from './Dialogs/reset-password-dialog.component';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.css']
})
export class UsersManagementComponent implements OnInit {
  displayedColumns: string[] = ['displayName', 'email', 'status', 'actions'];
  users: any[] = [];
  searchTerm: string = '';

  constructor(private adminService: AdminService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.adminService.getDirectoryUsers().subscribe(
      response => {
        this.users = response.data;
      },
      error => {
        console.error('Error fetching directory users', error);
      }
    );
  }  
  applyFilter() {
    
  return this.users.map(user => {
    user.loginEmail = user.identities?.find(id => id.signInType === "emailAddress")?.issuerAssignedId 
                      || user.userPrincipalName 
                      || "N/A";
    return user;
  }).filter(user => 
    user.displayName.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
    user.loginEmail.toLowerCase().includes(this.searchTerm.toLowerCase())
  );
}
  openResetPasswordDialog(user: any) {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      width: '400px',
      data: { user }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadUsers();
      }
    });
  }

  
}
