<h2>User Management</h2>

<!-- Search Box -->
<mat-form-field appearance="outline" class="search-box">
  <mat-label>Search Users</mat-label>
  <input matInput [(ngModel)]="searchTerm" placeholder="Search by name or email">
</mat-form-field>

<!-- Table Container -->
<div class="table-container">
  <table mat-table [dataSource]="applyFilter()" class="mat-elevation-z8 full-width">

    <!-- Name Column -->
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
    </ng-container>

    <!-- Email Column -->
    <!-- <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">{{ user.mail }}</td>
    </ng-container> -->

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">{{ user.loginEmail }}</td>
    </ng-container>
    

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let user">
        <mat-chip [color]="user.accountEnabled ? 'primary' : 'warn'">
          <strong>{{ user.accountEnabled ? 'Active' : 'Disabled' }}</strong>
        </mat-chip>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button (click)="openResetPasswordDialog(user)">
          <mat-icon color="primary">lock_reset</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>
