import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller/index';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { BuyerViewModel } from '../models/promotions/marketing.model';
import { NotificationViewModel } from '../models/seller/notificationViewmodel';

import Swal from 'sweetalert2';

interface PlatformHealthChecks {
  status: string;
  details: any;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-version': '3',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;

  private handleError: HandleError;



  constructor(private http: HttpClient, private utilsService: UtilsService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
  }
  getAdmin(): Observable<SellerViewModel> {
    const url = `${this.apiURl}/api/v1/seller`;    
    return this.http.get<SellerViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<SellerViewModel>('getSeller'))
    );
  }

  isPlatformAdmin(): Observable<boolean> {
    const url = `${this.apiURl}/api/v2/admin/rbac/is-platform-admin`;    
    return this.http.get<boolean>(url, httpOptions).pipe(
      catchError(this.handleError<boolean>('isPlatformAdmin'))
    );
  }
  GetBuyersNotifications(): Observable<NotificationViewModel[]> {
    const url = `${this.apiURl}/api/v1/Supplier/GetBuyersNotifications`;    
    return this.http.get<NotificationViewModel[]>(url, httpOptions).pipe(
      catchError(this.handleError<NotificationViewModel[]>('GetBuyersNotifications'))
    );
  }

  CloseNotification(NotificationViewModel: NotificationViewModel): Observable<boolean> {
    const url = `${this.apiURl}/api/v1/Supplier/CloseNotification`;    
    return this.http.put<boolean>(url, NotificationViewModel, httpOptions).pipe(
      catchError(this.handleError<boolean>('CloseNotification'))
    );
  }
  getBuyer(BuyerId: number): Observable<BuyerViewModel> {
    const url = `${this.apiURl}/api/v1/Buyer?Id=${BuyerId}`;    
    return this.http.get<BuyerViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<BuyerViewModel>('getBuyer'))
    );
  }
  getSupplier(): Observable<SupplierViewModel> {
    const url = `${this.apiURl}/api/v1/Supplier`;    
    return this.http.get<SupplierViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<SupplierViewModel>('getSupplier'))
    );
  }
  updateSupplier(Supplier: SupplierViewModel): Observable<SupplierViewModel> {
    const url = `${this.apiURl}/api/v1/Supplier/Update`;    
    return this.http.post<SupplierViewModel>(url, Supplier, httpOptions).pipe(
      catchError(this.handleError<SupplierViewModel>('updateSupplier'))
    );
  }

  getPlatformHealthChecks(): Observable<PlatformHealthChecks> {
    const url = `${this.apiURl}/api/v2/platform/health-checks`;
    return this.http.get<PlatformHealthChecks>(url, httpOptions).pipe(
      catchError(this.handleError<PlatformHealthChecks>('getPlatformHealthChecks'))
    );
  }
  getDirectoryUsers(): Observable<any> {
    const url = `${this.apiURl}/api/v2/admin/users`;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError<any[]>('getDirectoryUsers'))
    );
  }

  getCacheInfo(): Observable<string> {
    const url = `${this.apiURl}/api/v2/admin/platform/caching`;
    return this.http.get<string>(url, httpOptions).pipe(
      catchError(this.handleError<string>('getPlatformHealthChecks'))
    );
  }

  resetUserPassword(request: any): Observable<any> {
    const url = `${this.apiURl}/api/v2/admin/users/reset-password`;
    return this.http.post<any>(url, request, httpOptions).pipe(
      tap(success => {
        if (success) {
          Swal.fire('Success', 'password was reset successfully', 'success');
        } else {
          Swal.fire('Error', 'Failed to clear cache', 'error');
        }
      }),
      catchError(this.handleError<boolean>('clearCache'))
    );
  }

  clearCache(): Observable<boolean> {
    const url = `${this.apiURl}/api/v2/admin/platform/caching`;
    return this.http.put<boolean>(url, {}, httpOptions).pipe(
      tap(success => {
        if (success) {
          Swal.fire('Success', 'Cache cleared successfully', 'success');
        } else {
          Swal.fire('Error', 'Failed to clear cache', 'error');
        }
      }),
      catchError(this.handleError<boolean>('clearCache'))
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getAdmin().pipe(
      map(admin => !!admin),
      catchError(() => of(false))
    );
  }
}
