<h2 mat-dialog-title>Reset Password</h2>
<mat-dialog-content>
  <p>Reset password for <strong>{{ data.user.displayName }}</strong></p>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>New Password</mat-label>
    <input matInput [(ngModel)]="newPassword" type="password">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" (click)="resetPassword()" [disabled]="!newPassword">Reset</button>
</mat-dialog-actions>
