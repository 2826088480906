import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { IconsComponent } from '../../icons/icons.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { OnboardingComponent } from 'app/onboarding/onboarding.component';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule, MatCommonModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDividerModule } from '@angular/material/divider'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MarketUpdateDialog } from 'app/dialogs/marketUpdate/marketUpdate.dialog';
import { ResetPasswordDialogComponent } from 'app/users-management/Dialogs/reset-password-dialog.component';

// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { MarketUpdatesComponent } from 'app/market-updates/market-updates.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OnboardingDialog } from 'app/dialogs/onboarding/onboarding.dialog';
import { SubscriberApprovalDialog } from 'app/subscribers/dialogs/approval/subscriber-approval.dialog';
import { SellersComponent } from 'app/subscribers/management/sellers.component';
import { DateValidatorDirective } from 'app/shared/directives/dateValidator.directive';
import { PlatformManagementComponent } from '../../platform-management/platform-management.component';
import { UsersManagementComponent } from 'app/users-management/users-management.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    IntlModule,
    InputsModule,
    EditorModule,
    DialogsModule,
    PDFExportModule,
    GridModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatSliderModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCommonModule,
    MatListModule,
    NgxSpinnerModule
  ],
  declarations: [
    DashboardComponent,
    TableListComponent,
    IconsComponent,
    NotificationsComponent,
    OnboardingComponent,
    MarketUpdatesComponent,
    MarketUpdateDialog,
    ResetPasswordDialogComponent,
    OnboardingDialog,
    SubscriberApprovalDialog,
    SellersComponent,
    DateValidatorDirective,
    PlatformManagementComponent,
    UsersManagementComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})

export class AdminLayoutModule { }
